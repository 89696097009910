import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";


const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          data-tooltip-id="top-to-up-tooltip"
          data-tooltip-content={"Back to top"}
          className="fixed bottom-16 right-16 px-6 py-4   bg-white text-black rounded-full shadow-lg  transition-all duration-300 border border-black"
          aria-label="Back to top"
        >
        <span
                  className={`text-quotetext-200 arrow rotate-[316deg]  transition-all duration-200 ease-linear  `}
                ></span>
        </button>
      )}

<Tooltip
        id="top-to-up-tooltip"
        place="top"
        multiline={true}
        className="z-50"
      />
    </div>
  );
};

export default ScrollToTop;
