import React from "react";
import { useState, useRef, useEffect } from "react";
import Layout from "components/layout";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
// import PreTranslationOutputs from "./PreTranslationOutputs";
import Loader from "components/loader";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { Tooltip } from "react-tooltip";
import PostTranslationOutput from "./PostTranslationOutput";

const ResultPage = () => {
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [seeMoreText, setSeeMoreText] = useState(false);
  const textRef = useRef(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const location = useLocation();
  const [queryParams, setQUeryParams] = useState(false);
  const [shareId, setShareId] = useState(null);
  const [result, setResult] = useState(null);
  const [loader, setLoader] = useState(true);
  const [inputLang, setInputLang] = useState("");

  const query = new URLSearchParams(location.search).get("share-id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await http().get(`${endpoints.forms.aiTools}/${query}`);
        setResult(res);
        setText(res[0]?.meta?.source_text);
        setInputLang(res);
      } catch (err) {
        console.log(err.message || "An unexpected error occurred.");
      } finally {
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        const lineHeight = parseFloat(
          getComputedStyle(textRef.current).lineHeight
        );
        const maxLinesHeight = lineHeight * 3;

        if (textRef.current.scrollHeight > maxLinesHeight) {
          setIsTextOverflowing(true);
        } else {
          setIsTextOverflowing(false);
        }
      }
    };
    checkTextOverflow();
  }, [text, queryParams]);

  const sourceTextWordCount = result?.[0]?.meta?.source_text?.trim()?.split(/\s+/)
  .filter((word) => word.length > 0).length || 0;

const translatewordCount = result?.[0]?.meta?.translation_text?.trim()?.split(/\s+/)
  .filter((word) => word.length > 0).length || 0;

// const wordCountRatio = sourceTextWordCount > 0 ? translatewordCount / sourceTextWordCount : 0;
const wordCountRatio = sourceTextWordCount > 0 ? (translatewordCount / sourceTextWordCount).toFixed(2) : '0.00';
const geToolOutput = (toolName, dataList) => {
  return result?.find((item) => item.meta.tool_name === toolName);
};

  if (loader)
    return (
      <div className="grid place-content-center h-[calc(100vh-150px)]">
        <Loader />
      </div>
    );

  return (
    <div className="bg-[#F5F5F5] lg:px-0 px-4">
      <div className="pt-7">
        {!result ? (
          <ToolsBreadcrumb />
        ) : (
          <div className="flex justify-between max-w-7xl mx-auto gap-3  2xl:px-0 items-center text-base text-[#494949] font-opensans font-semibold ">
            <div className="flex gap-3">
              <Link to="/tools" className="flex gap-2 sm:gap-3 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                >
                  <path
                    d="M1 1L7 7L1 13"
                    stroke="#9C9C9C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span className="text-[#B0B0B0]">Tools</span>
              </Link>
              <Link to="/tools/post-translation">
                <button
                  onClick={() => {
                    setText("");
                    setQUeryParams(false);
                    navigate("/tools/post-translation");
                    setWordCount(0);

                    setShareId(null);
                  }}
                  className="flex gap-2 sm:gap-3 items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      d="M1 1L7 7L1 13"
                      stroke="#9C9C9C"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>               
                  <span className="text-[#B0B0B0]">
                    Post-translation Toolkit
                  </span>
                </button>
              </Link>
              <p className="flex gap-2 sm:gap-3 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                >
                  <path
                    d="M1 13L7 7L0.999999 1"
                    stroke="#575757"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span className="text-[#B0B0B0]">Results</span>
              </p>
            </div>
            <div className="flex items-center gap-2  ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
              >
                <path
                  d="M9.34949 0L18 3.02768V10C18 13.5701 15.8088 16.0657 13.7647 17.6151C12.5317 18.5419 11.1785 19.2971 9.74223 19.8599L9.66697 19.8884L9.64534 19.8962L9.63929 19.8979L9.63669 19.8988C9.63583 19.8988 9.63496 19.8988 9.34949 19.083L9.06316 19.8997L9.0597 19.8979L9.05365 19.8962L9.03202 19.8875L8.95676 19.8599C8.54877 19.7021 8.1476 19.5272 7.75434 19.3356C6.76159 18.8534 5.81726 18.2772 4.93427 17.6151C2.89102 16.0657 0.698975 13.5701 0.698975 10V3.02768L9.34949 0ZM9.34949 19.083L9.06316 19.8997L9.34949 20L9.63583 19.8997L9.34949 19.083ZM9.34949 18.1557L9.35728 18.1522C10.5542 17.6575 11.684 17.0137 12.7197 16.2362C14.5692 14.8356 16.2699 12.7881 16.2699 10V4.25606L9.34949 1.83391L2.42908 4.25606V10C2.42908 12.7881 4.12977 14.8339 5.97925 16.237C7.01731 17.0161 8.14971 17.6607 9.34949 18.1557ZM14.6021 6.83651L8.48531 12.9533L4.81489 9.28374L6.03894 8.05969L8.48444 10.5069L13.378 5.61332L14.6021 6.83651Z"
                  fill="#51CC56"
                />
              </svg>
              <p className="text-[#858585] font-semibold text-sm font-opensans">
                {" "}
                Your data is secure
              </p>
              <button
                data-tooltip-id="data-secure-tooltip"
                data-tooltip-content="Tomedes tools run on secure servers. General usage data is reviewed to improve the tools and user experience."
                className=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4ZM7.2 10.4H8.8V12H7.2V10.4ZM8.8 9.084V9.6H7.2V8.4C7.2 8.18783 7.28429 7.98434 7.43431 7.83431C7.58434 7.68429 7.78783 7.6 8 7.6C8.22726 7.59999 8.44985 7.53544 8.64186 7.41386C8.83387 7.29229 8.98741 7.11869 9.08462 6.91327C9.18182 6.70784 9.2187 6.47904 9.19095 6.25347C9.1632 6.02791 9.07196 5.81487 8.92787 5.63913C8.78377 5.46339 8.59273 5.33218 8.37698 5.26078C8.16122 5.18937 7.92963 5.18069 7.70914 5.23576C7.48865 5.29083 7.28833 5.40738 7.13149 5.57185C6.97465 5.73632 6.86774 5.94195 6.8232 6.1648L5.2536 5.8504C5.35091 5.36407 5.57561 4.9123 5.90474 4.54127C6.23387 4.17024 6.65561 3.89327 7.12687 3.73866C7.59813 3.58405 8.10199 3.55735 8.58695 3.66129C9.0719 3.76524 9.52056 3.99609 9.88706 4.33026C10.2535 4.66443 10.5247 5.08992 10.6729 5.56325C10.821 6.03658 10.8409 6.54076 10.7303 7.02425C10.6197 7.50774 10.3828 7.9532 10.0436 8.31509C9.70447 8.67698 9.27531 8.94233 8.8 9.084Z"
                    fill="#B0B0B0"
                  />
                </svg>
                <Tooltip
                  id="data-secure-tooltip"
                  place="bottom-end"
                  multiline={true}
                  className="z-50 !w-[300px] sm:!w-[400px] !font-opensans font-normal !text-sm !text-start"
                />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className={`max-w-7xl mx-auto py-12 lg:py-24 grid `}>
        <p className="text-[#424242] font-bold text-lg font-opensans">
          Source & Translated text
        </p>
        <hr className="border-[#C7C7C7] border mt-3 mb-4" />
        <div className="shadow-box relative p-3 rounded-lg ease duration-500">
          <div className="flex md:flex-row flex-col">
            <div>
              <p className="text-sm font-opensans font-bold text-[#00173A]">Source</p>
            <p
              dir={
                inputLang[0]?.meta?.source_language_code === "fa" ||
                inputLang[0]?.meta?.source_language_code === "he" ||
                inputLang[0]?.meta?.source_language_code === "ar"
                  ? "rtl"
                  : "ltr"
              }
              ref={textRef}
              className={`tool pt-4 lg:pb-0 b-2 pr-5 text-lg font-sans text-[#575757] w-full  col-span-[1.5] ${
                !seeMoreText && " line-clamp-3"
              }`}
              dangerouslySetInnerHTML={{
                __html: text?.replace(/\n/g, "<br />"),
              }}
            />
           </div>
           <div>
           <p className="text-sm font-opensans lg:px-4 font-bold text-[#00173A]">Translation</p>
            <p
              dir={
                inputLang[0]?.meta?.target_language_code === "fa" ||
                inputLang[0]?.meta?.target_language_code === "he" ||
                inputLang[0]?.meta?.target_language_code === "ar"
                  ? "rtl"
                  : "ltr"
              }
              ref={textRef}
              className={`tool w-full  lg:px-4  pt-4 text-lg font-sans text-[#575757] lg:border-l-[1.5px] lg:border-t-0 border-t-[1.5px] border-[#D9D9D9]  ${
                !seeMoreText && " line-clamp-3"
              }`}
              dangerouslySetInnerHTML={{
                __html: result[0]?.meta?.translation_text?.replace(
                  /\n/g,
                  "<br />"
                ),
              }} 
            />
             </div>
            <svg
              onClick={() => {
                setText("");
                setQUeryParams(false);
                navigate("/tools/post-translation");
                setWordCount(0);

                setShareId(null);
              }}
              className="absolute top-4 right-3 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M11.7472 10.5355C11.9081 10.6964 11.9986 10.9148 11.9986 11.1424C11.9986 11.37 11.9081 11.5884 11.7472 11.7493C11.5862 11.9103 11.3679 12.0007 11.1403 12.0007C10.9126 12.0007 10.6943 11.9103 10.5333 11.7493L6 7.21455L1.46523 11.7479C1.30427 11.9089 1.08595 11.9993 0.858315 11.9993C0.630676 11.9993 0.41236 11.9089 0.251395 11.7479C0.0904294 11.5869 2.39857e-09 11.3686 0 11.141C-2.39857e-09 10.9133 0.0904294 10.695 0.251395 10.5341L4.78616 6.00071L0.252822 1.46595C0.0918573 1.30498 0.00142808 1.08667 0.00142808 0.859029C0.00142808 0.63139 0.0918573 0.413074 0.252822 0.252109C0.413788 0.0911433 0.632104 0.000713906 0.859743 0.000713904C1.08738 0.000713902 1.3057 0.0911433 1.46666 0.252109L6 4.78687L10.5348 0.251394C10.6957 0.0904292 10.914 -3.79247e-09 11.1417 0C11.3693 3.79247e-09 11.5876 0.0904292 11.7486 0.251394C11.9096 0.41236 12 0.630675 12 0.858315C12 1.08595 11.9096 1.30427 11.7486 1.46524L7.21384 6.00071L11.7472 10.5355Z"
                fill="#69AAE8"
              />
            </svg>
          </div>

          {isTextOverflowing && (
            <div className="mt-4 flex items-center justify-between">
              <span className="text-black  ">
                {
                  text
                    .trim()
                    .split(/\s+/)
                    .filter((word) => word.length > 0).length
                }{" "}
                words
              </span>
              <button
                onClick={() => setSeeMoreText(!seeMoreText)}
                className="mx-auto max-w-max flex items-center text-[#5B93FF] font-semibold font-opensans"
              >
                {!seeMoreText ? "See more" : "Hide"}
                <svg
                  className={`ml-2.5 ${
                    seeMoreText && "rotate-180"
                  } ease-in-out duration-150`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                >
                  <path
                    d="M8.5 1.0625L4.74593 4.34765C4.68069 4.40471 4.59222 4.43679 4.5 4.43679C4.40778 4.43679 4.31931 4.40471 4.25407 4.34765L0.5 1.0625"
                    stroke="#5B93FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 4.56323L4.74593 7.84839C4.68069 7.90545 4.59222 7.93753 4.5 7.93753C4.40778 7.93753 4.31931 7.90545 4.25407 7.84839L0.5 4.56323"
                    stroke="#5B93FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <span className="text-black  ">
                {
                  result[0]?.meta?.translation_text
                    .trim()
                    .split(/\s+/)
                    .filter((word) => word.length > 0).length
                }{" "}
                words
              </span>
            </div>
          )}
        </div>
      </div>

      <PostTranslationOutput
        result={result}
        wordCountRatio={wordCountRatio}
        sourceTextWordCount={sourceTextWordCount}
        translatewordCount={translatewordCount}
        // inputLang={inputLang}
        // wordCount={wordCount}
      />
    </div>
  );
};

export default ResultPage;
