import React, { useState, useEffect, useRef } from "react";
import { Languages } from "components/QualityTools/LanguagesData";
import { parseResult } from "utils/toolsresult";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Tooltip } from "react-tooltip";
import InfoIcon from "assets/info.svg";
import "swiper/css";
import "swiper/css/grid";

import { Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Nexticon from "assets/nexticon.svg";
import Pre from "assets/pre.svg";
import DownloadSection from "utils/resultDownload";
import * as XLSX from "xlsx";

const GlossaryGenerator = ({ analysisResult,headerText ,glossaryGeneratorRef}) => {
  const [divHeight, setDivHeight] = useState(0);
  const refDiv = useRef(null);
  const outputRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [isLgScreen, setIsLgScreen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFormat, setSelectedFormat] = useState("Download CSV");
  const downloadOutputFormat = ["Download CSV", "Download XLS"];
  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.activeIndex + 1;
    setCurrentPage(currentIndex);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsLgScreen(window.innerWidth > 1024);
      const handleResize = () => {
        setIsLgScreen(window.innerWidth > 1024);
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const findLanguageNameByCode = (code) => {
    const language = Languages.find((lang) => lang.code === code);
    return language ? language.name : null;
  };

  const sourceLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.source_language_code
  );
  const targetLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.target_language_code
  );

  const resultValue = parseResult(analysisResult?.result);
  const glossary = resultValue?.glossary;


  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.base?.offsetHeight);
    }
  }, [refDiv, analysisResult]);

  const copyText = () => {
    if (outputRef.current) {
      const textToCopy = outputRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };
  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
      downloadCSV();
    } else if (format === "Download XLS") {
      downloadXLS();
    }
  };
  const downloadCSV = () => {
    if (!resultValue || !Array.isArray(resultValue.glossary)) return;

    const csvRows = [];
    csvRows.push([sourceLanguageName, targetLanguageName]);

    resultValue.glossary.forEach((item) => {
      csvRows.push([item.source_term, item.translated_term]);
    });

    const csvString = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "glossary.csv";
    a.click();
    URL.revokeObjectURL(url);
  };
  const downloadXLS = () => {
    if (!resultValue || !Array.isArray(resultValue.glossary)) return;
  
    const rows = [];
    rows.push([sourceLanguageName, targetLanguageName]);
    resultValue.glossary.forEach((item) => {
      rows.push([item.source_term, item.translated_term]);
    });
    const ws = XLSX.utils.aoa_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Glossary");
    XLSX.writeFile(wb, "Glossary.xlsx");
  };
  

  return (
    <div className="bg-white rounded-lg p-4">
      <div ref={glossaryGeneratorRef}  className=" w-full px-3 rounded-lg mb-4 flex items-center justify-between gap-5">
        <p className="text-base font-opensans  font-semibold flex flex-col">
          <span>{headerText}</span>
          <span className="text-[#6E6E6E] font-opensans font-semibold text-[12px]">
          Extracts and lists essential industry-specific terms from the source and matches them with their corresponding translation in the given translated text.
            </span>
          {/* <img src={InfoIcon} alt="info" /> */}
        </p>
        <div className="flex gap-4 items-center justify-between  ">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>
          <DownloadSection
                handleDownload={handleDownload}
                selectedFormat={selectedFormat}
                handleFormatChange={handleFormatChange}
                downloadOutputFormat={downloadOutputFormat}
              />
        </div>
        <Tooltip
          id="copy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
       
      </div>
      <ul
        style={{ maxHeight: isLgScreen ? `${divHeight - 22}px` : "" }}
        className=" w-full text-sm text-left rtl:text-right relative overflow-y-auto  shadow-md sm:rounded-lg"
        ref={outputRef}
      >
        <li className="grid grid-cols-2">
          <p className="px-6 py-3 bg-[#BDD3FF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
            {sourceLanguageName}
          </p>
          <p className="px-6 py-3 bg-[#E0EBFF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
            {targetLanguageName}
          </p>
        </li>
        {glossary?.length > 10 ? (
        <div className="mt-2">
        <Swiper
          grid={{
            rows: 10,
          }}
          spaceBetween={30}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          onSlideChange={handleSlideChange}
          className="mySwiper !h-auto md:!h-[640px] !pb-[60px]  "
          modules={[Grid, Navigation]}
          breakpoints={{
            332: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 1,
              // slidesPerGroup: 1,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 1,
              // slidesPerGroup: 1,
              spaceBetween: 30,
            },
          }}
        >
          <div className="mt-6 grid gap-2 ">
            {glossary &&
              glossary?.map((item, index) => (
                <SwiperSlide key={index} className=" lg:!h-6 !bg-transparent">
                  <li className="flex" key={index}>
                    <p className="p-3 flex-1 text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                      {item.source_term}
                    </p>
                    <p className="p-3 bg-[#F0F5FF] flex-1 border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                      {item.translated_term}
                    </p>
                  </li>
                </SwiperSlide>
              ))}
          </div>
           <div className="h-16 mt-4  flex gap-2 items-center justify-center ">
                        <div
                          // style={{ border: "none", outline: "none" }}
                          className="absolute -bottom-[60px] w-[200px]  h-24  flex items-center justify-center gap-5 "
                        >
                          <img
                            style={{ height: "60px", width: "60px" }}
                            src={Nexticon}
                            alt="Next"
                            className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
                          />
                          <p className="mb-12 text-base font-semibold font-opensans text-[#424242]">
                            {" "}
                            Page {currentPage} of {totalPages}{" "}
                          </p>
                          <img
                            style={{ height: "60px", width: "60px" }}
                            src={Pre}
                            alt="Previous"
                            className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
                          />
                        </div>
                      </div>
        </Swiper>
        </div>
        ) : (
          <div className="mt-2 grid gap-2 ">
          {glossary &&
            glossary?.map((item, index) => (
   
                <li className="flex" key={index}>
                  <p className="p-3 flex-1 text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                    {item.source_term}
                  </p>
                  <p className="p-3 bg-[#F0F5FF] flex-1 border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                    {item.translated_term}
                  </p>
                </li>

            ))}
        </div>
        )}
      </ul>
    </div>
  );
};

export default GlossaryGenerator;
