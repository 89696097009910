import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/grid";

import Nexticon from "assets/nexticon.svg";
import Pre from "assets/pre.svg";
import { Tooltip } from "react-tooltip";

import CopyIcon from "assets/copytools.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as XLSX from "xlsx";
import DownloadSection from "utils/resultDownload";

const WrongSourceQaOutput = ({ sourceOutput,headerText,incorrectTranslationRef }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFormat, setSelectedFormat] = useState("Download CSV");
  const downloadOutputFormat = ["Download CSV", "Download XLS"];
  const sliderRef = useRef(null);
  const refDiv = useRef(null);
  const [copySuccess, setCopySuccess] = useState("Copy");

  const keyTermResult = sourceOutput?.result?.QA_Analysis;

  const wrongResult = keyTermResult?.wrong;

  const copyText = () => {
    if (refDiv.current) {
      const textToCopy = refDiv.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };
  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
        downloadCSV();
    } else if (format === "Download XLS") {
        downloadXLS();
    }
  };


  const downloadCSV = () => {


    const headers = ["Source", "Translation", "Issue", "Correction"];
    const csvRows = [
      headers.join(","), 
      ...wrongResult.map((item) =>
        [item.Source, item.Translation, item.Issue, item.Correction]
          .map((val) => `"${val}"`)
          .join(",")
      ),
    ];

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "wrong_translations.csv");
    link.click();
  };

  const downloadXLS = () => {
    // if (!data || data.length === 0) return;

    const worksheet = XLSX.utils.json_to_sheet(wrongResult);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Incorrect Translations");

    XLSX.writeFile(workbook, "wrong_translations.xlsx");
  };
  const itemsPerPage = 5;

  useEffect(() => {
    setTotalPages(Math.ceil(wrongResult?.length / itemsPerPage));
  }, [wrongResult]);

  const chunkArray = (array, size) => {
    return Array.from(
      { length: Math?.ceil(array?.length / size) },
      (_, index) => {
        return array.slice(index * size, index * size + size);
      }
    );
  };

  const paginatedResults = chunkArray(wrongResult, itemsPerPage);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setCurrentPage(next + 1);
    },
  };

  const handleNextSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handlePrevSlide = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="bg-white rounded-lg p-4">
      <div ref={incorrectTranslationRef} className=" w-full px-3 rounded-lg mb-4 flex items-center justify-between gap-5">
        <p className="text-base font-opensans  font-semibold flex flex-col">
          <span>{headerText}</span>
          <span className="text-[#6E6E6E] font-opensans font-semibold text-[12px]">
          These translations contain issues that must be corrected for accuracy, consistency, or proper localization.
          </span>
        </p>
        <div className="flex gap-4 items-center justify-between  ">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>
          <DownloadSection
            handleDownload={handleDownload}
            selectedFormat={selectedFormat}
            handleFormatChange={handleFormatChange}
            downloadOutputFormat={downloadOutputFormat}
          />
        </div>
        <Tooltip
          id="copy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
      </div>
      <ul
        ref={refDiv}
        className=" w-full text-sm text-left rtl:text-right relative    shadow-md sm:rounded-lg "
      >
        <li className="flex">
          {["Source", "Translation", "Issue", "Correction"].map(
            (heading, index) => (
              <p
                key={index}
                className={`p-3 w-[25%] text-start bg-${
                  index % 2 === 0 ? "[#E0EBFF]" : "[#BDD3FF]"
                } font-opensans text-base font-semibold break-words`}
              >
                {heading}
              </p>
            )
          )}
        </li>
        {wrongResult?.length > 5 ? (
          <div className="mt-2">
            <Slider {...settings} ref={sliderRef}>
              {paginatedResults.map((page, pageIndex) => (
                <div
                  key={pageIndex}
                  className="!flex !flex-col !gap-4 lg:!h-max !my-2"
                >
                  {page.map((item, idx) => (
                    <li key={idx} className="flex h-max lg:h-max">
                      {["Source", "Translation", "Issue", "Correction"].map(
                        (key, index) => (
                          <p
                            key={index}
                            className={`p-3 w-[25%] bg-${
                              index % 2 === 0 ? "[#F0F5FF]" : "[#E0EBFF]"
                            } text-start text-[#202020] font-opensans text-base font-normal break-words`}
                          >
                            {item[key]}
                          </p>
                        )
                      )}
                    </li>
                  ))}
                </div>
              ))}
            </Slider>

            <div className="h-16 mt-4  flex gap-2 items-center justify-center ">
              <div className="absolute -bottom-[39px] w-[200px]  h-24  flex items-center justify-center gap-5 ">
                <img
                  onClick={handlePrevSlide}
                  style={{ height: "60px", width: "60px" }}
                  src={Nexticon}
                  alt="Next"
                  className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
                />
                <p className="mb-12 text-base font-semibold font-opensans text-[#424242]">
                  {" "}
                  Page {currentPage} of {totalPages}{" "}
                </p>
                <img
                  onClick={handleNextSlide}
                  style={{ height: "60px", width: "60px" }}
                  src={Pre}
                  alt="Previous"
                  className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className=" w-full text-sm text-left rtl:text-right relative  shadow-md sm:rounded-lg">
            <div className="flex flex-col gap-2 mt-2">
              {paginatedResults.map((page, pageIndex) => (
                <div
                  key={pageIndex}
                  className="!flex !flex-col !gap-4 lg:!h-max !my-2"
                >
                  {page.map((item, idx) => (
                    <li key={idx} className="flex h-max lg:h-max">
                      {["Source", "Translation", "Issue", "Correction"].map(
                        (key, index) => (
                          <p
                            key={index}
                            className={`p-3 w-[25%] bg-${
                              index % 2 === 0 ? "[#F0F5FF]" : "[#E0EBFF]"
                            } text-start text-[#202020] font-opensans text-base font-normal break-words`}
                          >
                            {item[key]}
                          </p>
                        )
                      )}
                    </li>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </ul>
    </div>
  );
};

export default WrongSourceQaOutput;
