import React, { useState, useEffect, useRef } from "react";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Tooltip } from "react-tooltip";
import InfoIcon from "assets/info.svg";
import * as XLSX from "xlsx";
import DownloadSection from "utils/resultDownload";

const ConsistencyChecker = ({ analysisResult,headerText,consistencyCheckerRef }) => {
  const [divHeight, setDivHeight] = useState(0);
  const refDiv = useRef(null);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [selectedFormat, setSelectedFormat] = useState("Download CSV");
  const downloadOutputFormat = ["Download CSV", "Download XLS"];
  const { data } = useFetchLanguages();

  if (refDiv.current) {
    setDivHeight(refDiv.current.offsetHeight);
  }

  const resultValue = analysisResult?.result;

  const inconsistencies = resultValue?.inconsistencies;

  const filteredInconsistencies = Object.values(inconsistencies || {}).map(
    (item) => ({
      term: item.term,
      inconsistent_translations: item.inconsistent_translations,
    })
  );
  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.base?.offsetHeight);
    }
  }, [refDiv, analysisResult]);

  const copyText = () => {
    if (refDiv.current) {
      const textToCopy = refDiv.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleDownload = (format) => {
    if (format === "Download CSV") {
      downloadCSV();
    } else if (format === "Download XLS") {
      downloadXLS();
    }
  };

  const downloadCSV = () => {
    if (!analysisResult || !refDiv.current) return;
  
    const resultValue = analysisResult.result;

    const rows = [];
  
    if (resultValue?.overall_consistency_score !== "100%") {

      rows.push(["Source Term", "How It Was Translated"]);

      const inconsistencies = resultValue?.inconsistencies || {};
      Object.values(inconsistencies).forEach((item) => {
        const translations = item.inconsistent_translations.join("; ");
        rows.push([item.term, translations]);
      });
    }
    if (resultValue?.overall_consistency_score === "100%") {
      rows.push([`All good! ${resultValue?.message}`]);
    }
  
    rows.push(["Overall Consistency Score", resultValue?.overall_consistency_score]);

    const csvContent = rows.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "consistency_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadXLS = () => {
    if (!analysisResult || !refDiv.current) return;
  
    const resultValue = analysisResult.result;
  
    const rows = [];
    if (resultValue?.overall_consistency_score !== "100%") {
      rows.push(["Source Term", "How It Was Translated"]);
  
      const inconsistencies = resultValue?.inconsistencies || {};
      Object.values(inconsistencies).forEach((item) => {
        const translations = item.inconsistent_translations.join("; ");
        rows.push([item.term, translations]);
      });
    }
  
    if (resultValue?.overall_consistency_score === "100%") {
      rows.push([`All good! ${resultValue?.message}`]);
    }
  
    rows.push(["Overall Consistency Score", resultValue?.overall_consistency_score]);
    const ws = XLSX.utils.aoa_to_sheet(rows); 
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "consistency_report XLSX");
    XLSX.writeFile(wb, "consistency_report.xlsx");
  };
  
  
  

  return (
    <div className="bg-white border border-[#5B93FF] rounded-lg overflow-hidden px-4">
      <div ref={consistencyCheckerRef} className="flex justify-between items-center border-b border-[#6E6E6E] bg-white">
        <h2 className="text-base font-opensans text-[#6E6E6E] font-bold py-3  flex flex-col ">
          <span>{headerText}</span>{" "}
          {/* <img src={InfoIcon} alt="info" className="ml-2" /> */}
          <span className="text-[#6E6E6E] font-opensans font-semibold text-[12px]">Automatically detects and highlights inconsistently translated terms.</span>
        </h2>
        
        {/* {!analysisResult.result.consistency_result == "100%" && ( */}
        <div className="flex gap-4 ">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>
          <DownloadSection
                handleDownload={handleDownload}
                selectedFormat={selectedFormat}
                handleFormatChange={handleFormatChange}
                downloadOutputFormat={downloadOutputFormat}
              />
        </div>
        {/* )} */}
        <Tooltip
          id="copy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />

      </div>
      <div ref={refDiv}>
      <div ref={refDiv} className={`my-4 flex flex-col gap-2 overflow-y-auto`}>
        {resultValue?.overall_consistency_score != "100%" ? (
          <div>
            <div className="grid grid-cols-2 rounded-t-lg">
              <p className="px-6 py-3 bg-[#BDD3FF] border-none outline-none text-[#575757] font-opensans rounded-tl-lg text-start font-normal">
              Source Term
              </p>
              <p className="px-6 py-3 bg-[#E0EBFF] border-none outline-none text-[#575757] font-opensans rounded-tr-lg text-start font-normal">
              How It Was Translated
              </p>
            </div>
            <div className="mt-2 grid gap-2">
              {filteredInconsistencies.map((item, index) => (
                <div className="grid grid-cols-2" key={index}>
                  <p className="p-3 w-full  text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                    {item.term}
                  </p>
                  <ul className="py-3 pr-5 pl-8 bg-[#F0F5FF] w-full list-disc border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                    {item.inconsistent_translations.map((translation, i) => (
                      <li key={i} className="text-[#575757]  ">
                        {translation}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[230px] lg:h-full w-full  bg-white ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="84"
              height="80"
              viewBox="0 0 84 80"
              fill="none"
            >
              <path
                d="M0 79.4999V70.2058H56V79.4999H0ZM0 42.3234V33.0293H29.4C29.9444 34.7332 30.5667 36.3597 31.2667 37.9087C31.9667 39.4577 32.8222 40.9293 33.8333 42.3234H0ZM0 60.9117V51.6175H43.8666C45.6555 52.7018 47.5611 53.6127 49.5833 54.35C51.6055 55.0873 53.7444 55.6094 56 55.9161V60.9117H0Z"
                fill="#BDD3FF"
              />
              <path
                d="M57.4 34.1912L73.85 17.6941L70.5834 14.4412L57.4 27.5691L50.75 20.9471L47.4834 24.3162L57.4 34.1912ZM60.6667 46.9706C54.2111 46.9706 48.7092 44.7044 44.1607 40.1719C39.6123 35.6395 37.3365 30.1606 37.3334 23.7353C37.3303 17.31 39.606 11.8311 44.1607 7.29865C48.7154 2.76621 54.2174 0.5 60.6667 0.5C67.116 0.5 72.6196 2.76621 77.1774 7.29865C81.7351 11.8311 84.0094 17.31 84 23.7353C83.9907 30.1606 81.7149 35.6411 77.1727 40.1766C72.6305 44.7121 67.1285 46.9768 60.6667 46.9706Z"
                fill="#75D778"
              />
            </svg>
            <p className="text-sm text-[#575757] font-opensans font-normal text-center  mt-8">
              All good! {analysisResult?.result?.message}
            </p>
          </div>
        )}
      </div>

      <div  className="flex gap-2 items-center justify-center p-4">
        <p className="text-base font-opensans text-[#0A2641] font-semibold">
          Overall Consistency Score
        </p>

        <p className="text-white rounded-3xl px-3 py-1 bg-[#16548D] ">
          {analysisResult?.result?.overall_consistency_score}
        </p>
      </div>
      
</div>
    </div>
  );
};
export default ConsistencyChecker;
