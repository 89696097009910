
import React from "react";
import { Tooltip } from "react-tooltip";
import InfoIcon from "assets/info.svg";

const PostLanguageOutput = ({
  geToolOutput,
  wordCountRatio,
  translatewordCount,
  sourceTextWordCount,
  headerText,
  languageCheckerRef
}) => {
  const tooltipText =
    translatewordCount > sourceTextWordCount
      ? `The word count ratio shows if a translation is longer or shorter than the source text. A ratio above 1 means it's longer, while below 1 means it's shorter.`
      : "The word count ratio shows if a translation is longer or shorter than the source text. A ratio above 1 means it's longer, while below 1 means it's shorter.";

  return (
    <div>
      <div className="shadow-box p-5 rounded-lg border border-lightBlue h-full ">
        <div ref={languageCheckerRef} className="flex justify-between gap-12 items-center">
          <p className="text-[#6E6E6E] font-semibold">
           {headerText}
          </p>

          <div className="flex items-center gap-4 overflow-x-auto text-[#2180D9] ">
            {geToolOutput?.result?.detected_languages?.map(
              ({ language, percentage, i }) => (
                <React.Fragment key={i}>
                  <div className="text-lg font-bold">
                    {percentage}% {language}
                  </div>
                  <span className="last-of-type:hidden">/</span>
                </React.Fragment>
              )
            )}
          </div>
        </div>
        <hr className="my-3 border border-[#C7C7C7]" />

        <div className="flex justify-between items-center">
          <p className="text-[#6E6E6E] font-semibold">Word count ratio:</p>
          <p className="flex gap-2 items-center">
            <span className="text-lg font-bold text-[#2180D9]">
              {wordCountRatio}
            </span>
            <img
              data-tooltip-id="word_count-tooltip"
              data-tooltip-content={tooltipText}
              src={InfoIcon}
              alt="info"
              className="cursor-pointer"
            />
          </p>
        </div>
      </div>

      <Tooltip
        id="word_count-tooltip"
        place="bottom" 
        style={{ maxWidth: "300px" }} 
        multiline={true}
        className="z-50"
      />
    </div>
  );
};

export default PostLanguageOutput;
